const getMessageType = (msg) => {
  let type;
  if (msg.includes(".mp4")) {
    type = "mp4";
  } else if (msg.includes(".mp3")) {
    type = "mp3";
  } else if (msg.includes(".pdf")) {
    type = "pdf";
  } else {
    type = "img";
  }

  return type;
}

export const formatConversations = (conversationsObject) => {


  let conversations = [];
  let conversation = {};

  let conversationsVersions = ["2.0", "3.0", "4.0"];
  for (const conversationID in conversationsObject.conversations) {
    for (const messagesID in conversationsObject.conversations[conversationID].conversation) {


      let messagesConversation = Object.entries(conversationsObject.conversations[conversationID].conversation)
      .map(dayConversations => {
          let dayConversation;
          let messages = [];

          if ((conversationsVersions.includes(conversationsObject.conversations[conversationID].version)) || !conversationsObject.conversations[conversationID].messages) {
            dayConversation = { ...dayConversations[1], id: messagesID };

            if (dayConversation.messages) {
              messages = Object.entries(dayConversation.messages).map(messageObject => {
                let message = { message: '', time: '', from: '' }

                let messageIdArray = messageObject[0].split("-");

                if (messageIdArray[0].includes("Contact") || messageIdArray[0].includes("User")) {
                  message.from = messageObject[0].split("-")[0];
                  message.time = messageObject[0].split("-")[1];
                } else {
                  message.from = messageObject[0].split("-")[1];
                  message.time = messageObject[0].split("-")[0];
                }

                if (messageObject[0].includes("att")) {
                  message.type = getMessageType(messageObject[1]);
                }

                message.message = messageObject[1];

                return message;

              });
            }

          } else {
            messages = dayConversations[1].split(';@').map(msg => {

              dayConversation = {
                date: conversationsObject.conversations[conversationID].start,
                channel: conversationsObject.conversations[conversationID].lastChannel
              }
              let message = { message: '', time: '', from: '' }
              message.from = msg.includes("{User}") ? "User" : "Contact";

              if (msg.includes("{att}")) {
                message.message = msg.split('{Time}:')[0].replace(`{${message.from}}{att}:`, '').replace('{UserControl}:', '');
                message.type = getMessageType(message.message);
              } else {
                message.message = msg.split('{Time}:')[0].replace(`{${message.from}}:`, '').replace('{UserControl}:', '');
              }

              message.time = msg.split('{Time}:')[1].replace('{Time}:', '');

              return message;
            })

          }

          return { ...dayConversation, messages };

      })

        
      const humanMessages = messagesConversation[
        messagesConversation.length - 1
      ].messages.filter((e) => e.from !== "System");
      
      let keysConversation = conversationsObject.conversations[conversationID].conversation && Object.keys(conversationsObject.conversations[conversationID].conversation);
      let lastConversation = conversationsObject.conversations[conversationID].conversation[keysConversation[keysConversation.length-1]];
      
      conversation = {
        ...conversationsObject.conversations[conversationID],
        agent: conversationsObject.conversations[conversationID].owner,
        date: conversationsObject.conversations[conversationID].start,
        key: conversationsObject?.conversations[conversationID]?.key ?? "",
        channel:
          conversationsObject.conversations[conversationID].channel ||
          conversationsObject.conversations[conversationID].lastChannel,
        direction: "Entrante",
        conversation: messagesConversation,
        lastInteraction: Number(humanMessages[humanMessages.length - 1]?.time),
        properties:lastConversation.requiredPropsValues,
        group: lastConversation.group,
        subgroup: lastConversation.subgroup
      };
      
     
    }
    
    conversations.push(conversation);
  }
  
  conversations.sort((a, b) => b.lastInteraction - a.lastInteraction || b.dateUpdate - a.dateUpdate || b.date - a.date);

  return conversations;
}

export const formatMessages = messages => {
  return Object.entries(messages).map(messageObject => {
    let message = { message: '', time: '', from: '',status :'',caption:'',error:''}

    message.from = messageObject[1].type?.toLowerCase()?.includes("contact")?"Contact":messageObject[1].type;
    message.time = messageObject[1].date;
    message.status = messageObject[1]?.status?.status;
    message.error = messageObject[1]?.status?.code;
    message.caption = messageObject[1]?.caption;
    if (messageObject[1].type.includes("att")) {
      message.type = getMessageType(messageObject[1].message);
    }

    message.message = messageObject[1].message;

    return message;

  })

}
export const formatGroupedConversations = (conversationsObject, phoneLines) => {


  let formattedConversations = [];
  for (const conversationItem of conversationsObject.conversations) {
    let conversation = {};
    for (const messagesID in conversationItem.conversations) {

      let messagesConversation = Object.entries(conversationItem.conversations)
        .map(dayConversations => {
          let dayConversation;
          let messages = [];

          if (!conversationItem.messages) {
            dayConversation = { ...dayConversations[1], id: messagesID };

            // if (dayConversation.messages) {
            //   messages = Object.entries(dayConversation.messages).map(messageObject => {
            //     let message = { message: '', time: '', from: '' }
            //     message.from = messageObject[1].type;
            //     message.time = messageObject[1].date;

            //     if (messageObject[1].type.includes("att")) {
            //       message.type = getMessageType(messageObject[1].type);
            //     }

            //     message.message = messageObject[1].message;

            //     return message;

            //   });
            // }
          }
          return { ...dayConversation, messages };

        })

        
      conversation = {
        ...conversationItem,
        agent: conversationItem?.owner,
        date: conversationItem?.conversations[messagesID].date,
        key: conversationItem?.conversationGroupId ?? "",
        channel:
          conversationItem?.conversations[messagesID].channel ||
          conversationItem?.lastChannel,
        direction: "Entrante",
        conversation: messagesConversation,
        phoneLines,
      };
        
    }
    
    formattedConversations.push(conversation);        
  }
    
  return formattedConversations;
}


export const formatAgents = (agents) => {
  const data = agents.map(agent => ({
    label: `${agent.name} ${agent.lastname}`,
    description: agent.email,
    value: agent.email.replace(/\./g, '').replace(/-/g, '').replace(/@/g, ''),
    selected: false
  }));

  return data;
}


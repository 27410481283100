import React from 'react'
import RowLoading from '../Loading/RowLoading';

const TableContent = ({ data, row: Row, colsCount = 4, removeRow,updateRow, isEditModeOn }) => {
  return (
    <>
      {
        data &&
        <>
          {
            data.length > 0 ?
            data.map((row, i) => (
                
                <Row
                  {...row}
                  key={i}
                  i={i}
                  removeRow={removeRow}
                  updateRow={updateRow}
                  isEditModeOn={isEditModeOn}
                />
            ))
            : <tr><td rowSpan={colsCount} colSpan={4}>No se han encontrado datos.</td></tr>
          }
        </>
      }

      {(!data === true) && <RowLoading cells={colsCount} rows={5} />}
    </>
  )
}

export default TableContent
import React, { useState } from 'react'
import styled from 'styled-components'
import Loading from '../../../../components/Loading/Loading';
import Utils from '../../../../utils';
import { AlertConfirmHTML, AlertForm, ICON } from '../../../../utils/Alert';

const SmallButton = styled.button`
  /* background-color: var(--theme-color); */
  background: var(--dark-color);
  border: none;
  border-radius: 6px;
  color: white;
  margin: 4px auto;
  display: block;
`;

const FooterArea = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 0;
  z-index: 16;
  width: 100%;
  padding: 4px 8px;
  height: 44px;
  font-size: 12px;
  background: var(--dark-color);
`;

const ChatFooter = ({ fkey, sid, conversationsId, assesor, emailContact, identificatorCode, idLastConversation,state,group,subgroup,newAsesor,toggle,setDataRedirect,line }) => {
  const [isLoading, setIsLoading] = useState(false);
  
  
  const handleClick = async (e) => {
    AlertConfirmHTML("¿Seguro que desea cerrar la conversación?", "El cliente aún podría estar interesado en continuar, ¿Confirma que desea cerrar la conversación?", ICON.WARNING,
      () => updateConversation(),
      () => { console.log("Cancel delete") },
      "Cerrar conversación", "Volver a las conversaciones"
    );
  }
  const handleRedirect = async (e) => {
    setDataRedirect({
      conversationsId,
      group:group.value,
      subgroup:subgroup.value,
      newAsesor:newAsesor.value,
      key: fkey,
      sid
    })
  }

  const updateConversation = () => {

    setIsLoading(true);   
    Utils.Petition.post('/conversations/close-conversation',
      {
        sid,
        assesor,
        conversationsId:conversationsId, 
        line
      },
      () => {
        AlertForm({
          title: "Cambio de estado",
          icon: ICON.SUCCESS,
          text: "Se cerró correctamente la conversación.",
          timer: 4000
        });
        return;
      },
      (error) => {
        AlertForm({
          title: "No ha sido posible cerrar la conversación",
          icon: ICON.ERROR,
          text: "Ha ocurrido un error al cerrar la conversación, intente nuevamente.",
          timer: 4000
        });

        console.error(error);
      },
      () => setIsLoading(false)
    );
  }

  return (
    <FooterArea>
      
      {(state!="close") &&<SmallButton onClick={(e) => handleClick(e)} disabled={isLoading}>
        {
          isLoading ? <Loading /> : "Cerrar conversación"
        }
      </SmallButton>}
      <br />
      {(state!="close") &&<SmallButton onClick={(e)=>{toggle();handleRedirect();}} disabled={isLoading}>
        {
          isLoading ? <Loading /> : "Redireccionar"
        }
      </SmallButton>}
      
    </FooterArea>
  )
}

export default ChatFooter;
import React, { useState } from 'react';
import DetailTemplate from './DetailTemplate';
import { SectionContainer, SectionContent, SectionTitle } from '../styles';
import ArrowDownIcon from '../../../components/icons/arrows/ArrowDownIcon';
import Utils from '../../../utils';
import { AlertConfirmHTML, AlertForm, ICON } from '../../../utils/Alert';

const VisibleRow = (props) => {
  const [activeButtons, setActiveButtons] = useState(false);
  const [deletedRow, setDeletedRow ] = useState(false);
  const translate = {
    "MARKETING": 'Marketing',
    "UTILITY": 'Utilidad',
    "AUTHENTICATION": 'Autenticación'
  }
  const handleDeleteTemplate = ()=>{
    AlertConfirmHTML("Confirmación", "¿Estás seguro que desea eliminar esta plantilla?",ICON.WARNING,
      ()=> {
        Utils.Petition.delete(
          '/templates/'+props.id,
          (response) => {
            AlertForm({ title: 'Se eliminó la plantilla correctamente', icon: ICON.SUCCESS });
            setDeletedRow(true);
          },
          (err) => {
            AlertForm({ title: 'Sucedió un error inesperado', icon: ICON.ERROR });
          }
        );
      }
    )
  }
  return (
    deletedRow?
    <tr><td colSpan={4}>La plantilla <b>{props.name??props.code}</b> se eliminó correctamente</td></tr>
    :
    <tr>
      <td className='text-break'>
        {props.name} 
        <br /> 
        <code><small>{props.code}</small></code>
      </td>
      <td>{props.status}</td>
      
      <td style={{width: "500px"}}>
        <div className='form-group'>
          <span className="badge badge-info">Idioma: {props.language!=undefined ? props.language: 'es'}</span>
          &nbsp;&nbsp;
          <span className="badge badge-info">{props.category!=undefined ? translate[props.category]: translate['MARKETING']}</span>
        </div>
        {
          props.header!=undefined && 
          <DetailTemplate section={props.header} name="Encabezado" />
        }
        {
          props.body!=undefined && 
          <DetailTemplate section={props.body} name="Cuerpo" />
        }
        {
          props.body==undefined && 
          <DetailTemplate section={{type:'BODY', format:'TEXT', text: props.text, examples:[]}} name="Cuerpo" />
        }
        {
          props.footer!=undefined && 
          <DetailTemplate section={props.footer} name="Pie de página" />
        }
        {
          props.buttons!=undefined && props.buttons.length>0 &&
          <SectionContainer onClick={() => setActiveButtons(!activeButtons)}>
            <SectionTitle active={activeButtons}>
                Botones <ArrowDownIcon className="mx-1" />
            </SectionTitle>
            <SectionContent active={activeButtons}>
            {
              props.buttons.map((button,i)=>  
              <span key={i}><span className="badge badge-light">{button.text}</span>&nbsp;&nbsp;</span>
              )
            }
            </SectionContent>
          </SectionContainer>
        }
      </td>
      <td>
        <button type="button" class="btn btn-sm btn-danger" onClick={handleDeleteTemplate}>Eliminar</button>
      </td>
    </tr>
  )
}

export default VisibleRow;
import React, { useState, useEffect, useContext } from 'react';
import {PhoneLinesContext} from '../../../Context/PhoneLines';
import Utils from '../../../utils';
import TemplateSection from './TemplateSection';
import { MessageItem, SectionWhatsappPreview } from '../styles';
import { AlertForm, ICON } from '../../../utils/Alert';

const spacesRegex = /\s/g;
const specialCharactersRegex = /[^a-zA-Z0-9 ]/g;
const acentsRegex = /[\u0300-\u036f]/g;

const FormTemplate = () => {
  const [name, setName] = useState("");
  const [code, setCode] = useState("");
  const [templateHeader, setTemplateHeader] = useState({text:'', format:"TEXT", examples:[], type:'HEADER'});
  const [templateBody, setTemplateBody] = useState({text:'',  format:"TEXT", examples:[], type:'BODY'});
  const [templateFooter, setTemplateFooter] = useState({text:'',  format:"TEXT", type:'FOOTER'});
  const {phoneLine, getData} = useContext(PhoneLinesContext);
  const [language, setLanguage] = useState('es');
  const [category, setCategory] = useState('MARKETING');
  const [buttons, setButtons] = useState([]);

  useEffect(() => {
    setCode(name.replace(spacesRegex, "_").toLowerCase().normalize("NFD").replace(acentsRegex, "").replace(specialCharactersRegex, "_"));
  }, [name]);

  const handleSubmit = async function (event) {
    event.preventDefault();
    let body = JSON.parse(JSON.stringify(templateBody));
    body.text = body.text.trim();
    if(body.text){
      let data = {
        name, code, category, language, buttons,
        body,
        header: templateHeader,
        footer:templateFooter,
      };
      if(phoneLine){
        data.line = phoneLine
      }
      const endpoint = '/templates';
      Utils.Petition.post(endpoint, data, (_) => {
        getData();
        cleanForm();
        AlertForm({
          title: "Todo salió correctamente!",
          text: `Se ha guardado la plantilla correctamente`,
          icon: ICON.SUCCESS,
          position: "top-end",
        });
        Utils.Petition.logs({data, endpoint, type: "Create", register: "Plantillas: Crear" });
      },(response)=>{
        AlertForm({
          title: "Ocurrió un error inesperado!",
          text: response?.response?.data?.data ?? response.message,
          icon: ICON.ERROR,
          position: "top-end",
        });
      });
    }else{
      AlertForm({
        title: "¡Faltan datos!",
        text: `Por favor completa el cuerpo del mensaje`,
        icon: ICON.ERROR,
        position: "top-end",
      });
    }
  }
  function convertToWhatsAppHTML(text) {
    // Negrita
    text = text.replace(/\*(\S.*?\S)\*/g, '<b>$1</b>');
    
    // Itálica
    text = text.replace(/_(\S.*?\S)_/g, '<i>$1</i>');
    
    // Tachado
    text = text.replace(/~(\S.*?\S)~/g, '<s>$1</s>');
    
    // Monoespaciado
    text = text.replace(/```(.*?)```/gs, '<code>$1</code>');
    
    // Agrupar viñetas en un solo <ul>
    text = text.replace(/((^|\n)(\*|-)\s[^\n]+)+/gm, (match) => {
      const items = match.trim().split('\n').map(line => line.replace(/^\* |^- /, '<li>') + '</li>');
      return `<ul>${items.join('')}</ul>`;
    });
  
    // Reemplazar saltos de línea con <br>, excepto después de </ul>
    text = text.replace(/<\/ul>\n/g, '</ul>'); // Eliminar \n después de </ul>
    text = text.replace(/\n/g, '<br>'); // Reemplazar el resto de \n con <br>
  
    return text;
  }
  
  
  const addButton = ()=>{
    const button = {
      type: 'QUICK_REPLY',
      text: '',
      phone_number: '',
      url:''
    }

    localStorage.setItem('updated', 'true');
    
    setButtons([...buttons, button]);
  }
  const cleanForm = () => {
    setName("");
    setCode("");
    setTemplateHeader({text:'', format:"TEXT", examples:[], type:'HEADER'});
    setTemplateFooter({text:'', format:"TEXT", examples:[], type:'FOOTER'});
    setTemplateBody({text:'', format:"TEXT", examples:[], type:'BODY'});
    setButtons([]);
    localStorage.setItem('updated', '');
  }

  const setButtonProperty = (event, index, type)=>{
    let buttonArray = JSON.parse(JSON.stringify(buttons));
    buttonArray[index][type] = event.target.value;
    if(type==='type'){
      if(event.target.value==='QUICK_REPLY'){
        for(let i in buttonArray){
          buttonArray[i].type = 'QUICK_REPLY';
        }
      }else{
        for(let i in buttonArray){
          if(buttonArray[i].type==='QUICK_REPLY'){

            buttonArray[i].type = 'URL';
          }
        }
      }
    }
    setButtons(buttonArray);
  }
  const deleteButton = (i)=>{
    const temporalButtons = [...buttons];
    temporalButtons.splice(i,1);
    setButtons(temporalButtons);
  }
  const replaceByParameters = (text, examples)=>{

    examples.forEach((example, i)=>  {
      if(example){
        text = text.replace("{"+(i+1)+"}", example);
      }
    });
    return text;
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-4">
          <div className="form-group">
            <label htmlFor="name">Nombre</label>
            <input
              type="text"
              required
              placeholder='Ej: Bienvenida a nuevo cliente'
              className="form-control"
              id="name"
              name='name'
              value={name}
              onChange={(e) => {setName(e.target.value); localStorage.setItem('updated', 'true');}}
            />
            {name !== '' && <small>Código automático: <code>{code}</code></small>}
          </div>
          <div className='form-group'>
            <label htmlFor="language">Idioma</label>
            <select className='form-control' required id="language" name="language" value={language} onChange={event=>setLanguage(event.target.value)}>
              <option value="es">Español</option>
              <option value="es_ES">Español (España)</option>
              <option value="es_AR">Español (Argentina)</option>
              <option value="es_MX">Español (México)</option>
              <option value="en">Inglés</option>
              <option value="en_GB">Inglés (Reino Unido)</option>
              <option value="es_LA">Inglés (EE.UU.)</option>
              <option value="pt_BR">Portugués (Brasil)</option>
              <option value="pt_PT">Portugués (Portugal)</option>
            </select>
          </div>
          <div className='form-group'>
            <label htmlFor="language">Categoría</label>
            <select className='form-control' required id="language" name="language"  value={category} onChange={event=>setCategory(event.target.value)}>
              <option value="MARKETING">Marketing </option>
              <option value="AUTHENTICATION" disabled>Autenticación</option>
              <option value="UTILITY">Utilidad</option>
            </select>
          </div>
          <div>
            <button type="button" className='btn btn-sm btn-info' onClick={addButton}>Agregar botón</button>
            {buttons.length>0 && <div><small>Si un botón es de tipo respuesta rápida, todas las demás opciones deberán ser de respuesta rápida.</small></div>}
            {
              buttons.map((button, i)=>
              <div key={i}>
                <div className='form-group'>
                  <button type="button" className='btn btn-sm float-right' onClick={()=>deleteButton(i)}>Quitar</button>
                  <label htmlFor={'button-type-'+i}>Tipo</label>
                  <select id={'button-type-'+i} className='form-control' value={button.type} onChange={(e)=>setButtonProperty(e, i, 'type')}>
                    <option value="QUICK_REPLY">Respuesta rápida</option>
                    <option value="PHONE_NUMBER">Número de teléfono</option>
                    <option value="URL">Enlace URL</option>
                  </select>
                </div>
                <div className='form-group'>
                  <label htmlFor={'button-text-'+i}>Texto</label>
                  <input required type="text" id={'button-text-'+i} className='form-control' maxLength={25} value={button.text}  onChange={(e)=>setButtonProperty(e, i, 'text')}/>
                </div>
                {
                  button.type==='URL' &&
                  <div className='form-group'>
                    <label htmlFor={'button-url-'+i}>URL</label>
                    <input required type="text" id={'button-url-'+i} className='form-control' value={button.url}  onChange={(e)=>setButtonProperty(e, i, 'url')} />
                  </div>
                }
                {
                  button.type==='PHONE_NUMBER' &&
                  <div className='form-group'>
                    <label htmlFor={'button-phone-'+i} value={button.phone}>Teléfono</label>
                    <input required type="text" maxLength={20} id={'button-phone-'+i} className='form-control'  onChange={(e)=>setButtonProperty(e, i, 'phone_number')} />
                  </div>
                }
                <hr />
              </div>
              )
            }
          </div>
        </div>
        <div className="col-4">
          <TemplateSection category={category} getTemplate={templateHeader} setTemplate={setTemplateHeader} parameters name="Encabezado" props={{name:"headerTemplate", id:"headerTemplate", maxLength:60}} />
          <hr />
          <TemplateSection category={category} getTemplate={templateBody} setTemplate={setTemplateBody} parameters name="Cuerpo" props={{name:"bodyTemplate", id:"bodyTemplate"}} />
          <hr />
          <TemplateSection category={category} getTemplate={templateFooter} setTemplate={setTemplateFooter} parameters={false} name="Pie" props={{name:"footerTemplate", id:"footerTemplate", maxLength:60}} />
          <div className='form-group'>
            <small>
              <b>Los parámetros</b> debe incluirlos con llaves, y números consecutivos, ejemplo:
              <br />
              <code>Hola &#123;1&#125;, soy &#123;2&#125; te damos la bienvenida a ...</code> 
            </small>
          </div>
          <div className='form-group'>
            <small>
              <b>Los datos de muestra</b> son ejemplos que le permiten validar a Meta cómo sería el envío de una plantilla, permitiendo aumentar la confiabilidad y el éxito de aprobación.
            </small>
          </div>
        </div>
        <div className="col-4">
          <SectionWhatsappPreview>
            <div><b>Previsualización</b></div>
            <MessageItem>
              <div className='box'>
                { 
                  (templateHeader.text !== '' || templateHeader.format!=='TEXT') &&
                  <div className='p-header'>
                      {
                        templateHeader.format==='TEXT' && replaceByParameters(templateHeader.text, templateHeader.examples)
                      }
                      {
                        templateHeader.format!=='TEXT' && 
                        <div className='media-display'>
                          {
                            templateHeader.format==='IMAGE' && <img src="/image.png" alt="Imagen de muestra" />
                          }
                          {
                            templateHeader.format==='VIDEO' && <img src="/video.png" alt="Video de muestra"/>
                          }
                          {
                            templateHeader.format==='DOCUMENT' && <img src="/document.png" alt="Documento de muestra" />
                          }
                        </div>
                      }
                      
                  </div>
                }
                <div className="p-body" dangerouslySetInnerHTML={{__html:convertToWhatsAppHTML(replaceByParameters(templateBody.text , templateBody.examples))}} />
                { templateFooter.text !== '' && <div className="p-footer"> { templateFooter.text } </div> }
                <time>03:30</time>
              </div>
              {
                buttons.length>0 &&
                <div className='custom-button-group'>
                  {
                    buttons.map((button, i)=>(
                      <div className="custom-button" key={i}>
                        {
                          button.type!=='QUICK_REPLY' && 
                          <span className={"icon icon-"+(button.type==='PHONE_NUMBER'?'phone':'url')}></span> 
                        }
                        {button.text}
                      </div>
                    ))
                  }
                </div>
              }
            </MessageItem>
          </SectionWhatsappPreview>
        </div>
      </div>

      <div className="form-group">
        <button type="submit" className="btn btn-orange">
          Crear
        </button>
      </div>
    </form>
  )
}
export default FormTemplate;
import React, { useMemo, useRef } from 'react';
import Pagination from '../pagination/Pagination';
import { ConversationTable } from './styles';
import TableContent from './TableContent';
import useScrollOnDrag from '../../hooks/useScrollOnDrag';

const PaginationTable = ({ withoutRight, pages, setPages, row, content, headers = [],checks=false,dataSelectCheckbox,setDataSelectCheckbox }) => {

  const { ref, mouseDownHandler } = useScrollOnDrag();
  const tableRef = useRef(null);
  const headersAmount = useMemo(() => {
    let checkedAll=document.getElementById("checked-all")
    if(content?.length && checkedAll){
      let count=0;
      content.filter((e)=>{
        if(dataSelectCheckbox.find((a)=>a==e?.conversation?.conversationGroupId)){
          count++
        }
      })
      console.log("contador",count)
      if(content?.length == count ){
        checkedAll.checked = true
      }else{
        checkedAll.checked = false
      }
    }
    return headers.reduce((acc, header) => {
      let amount = acc;
      
      if (typeof header === "object") {
        amount += header.colSpan ?? 1;
      } else {
        amount += 1;
      }

      return amount;
    }, 0);
  }, [headers,content]);
  const handleAllSelectCheckbox = (e)=>{
    let selectedCheckbox=JSON.parse(JSON.stringify(dataSelectCheckbox))
   
    let allCheckbox=document.querySelectorAll("#select_checkbox")
    const checkedCount = Array.from(allCheckbox).filter(checkbox => checkbox.checked).length;
   
    if(content.length > checkedCount && e.target.checked){
      Array.from(allCheckbox).filter(checkbox => {
        if(!checkbox.checked){
          selectedCheckbox.push(checkbox.dataset.key)
          checkbox.checked=true
        }
      })
    } 
    else if(!e.target.checked){
      Array.from(allCheckbox).filter(checkbox => {
        if(checkbox.checked){
          checkbox.checked=false
          var indice = selectedCheckbox.indexOf(checkbox.dataset.key);
          selectedCheckbox.splice(indice, 1); 
        }
      })
    }
    setDataSelectCheckbox(selectedCheckbox);
  }
  return (
    <div
      className='p-0 table-responsive'
      ref={ref}
      onMouseDown={mouseDownHandler}
    >
      <ConversationTable id='table_exce' withoutRight={withoutRight ?? false} ref={tableRef}>
        <thead>
          <tr>
            
            {
              <>
                {checks && <th ><input type="checkbox" id='checked-all' onClick={handleAllSelectCheckbox}/></th> }
                {
                  headers.map((header, i) => {
                    let headerPros = (typeof header === "object") ? header : {};
                    
                    return (
                      
                      <th
                        key={header + i}
                        {...headerPros}
                      >
                        {
                          (typeof header === "object") ? header.value : header
                        }
                      </th>
                    )
                  })
                }
              </>
            }
          </tr>
        </thead>

        <tbody>
          <TableContent data={content} row={row} colsCount={headersAmount}   />
        </tbody>
      </ConversationTable>
      <Pagination pages={pages} setPages={setPages} fix={true} />
    </div>
  )
}

export default PaginationTable;